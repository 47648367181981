import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import Demo from "./demo";

import Sites from "./pages/customer/Sites/Sites";
import Kits from "./pages/customer/Kits/Kits";
import Kit from "./pages/customer/Kit";
import PublicSample from "containers/Sample/public";
import OrderForm from "./pages/customer/OrderForm/OrderForm";
import Orders from "./pages/customer/Orders/Orders";
import Order from "./pages/customer/Order/Order";
import Leads from "./pages/admin/Leads/Leads";
import Accounts from "./pages/admin/Accounts/Accounts";
import AccountDetails from "./pages/admin/Accounts/AccountDetails/AccountDetails";
import Users from "./pages/admin/Users/Users";
import MagicTable from "./pages/admin/MagicTable/MagicTable";
import RunbooksPage from "./pages/admin/Runbooks/RunbooksPage";
import UserForm from "./containers/UserForm/UserForm";
import KitSelectionStatuses from "./pages/admin/KitSelectionStatuses/KitSelectionStatuses";
import AdminKitsPage from "./pages/admin/Kits/Kits";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import PilotSample from "./pages/customer/PilotSample/PilotSample";
import Report from "./pages/customer/Report/Report";
import Auth from "./containers/Auth";
import NetworkSignUp from "./containers/SignUp/NetworkSignUp";
import NetworkCustomerOptIn from "./containers/SignUp/NetworkCustomerOptIn";
import CDCSignUp from "./containers/SignUp/CDCSignUp";
import NIDASignUp from "./containers/SignUp/NIDASignUp";
import TubesReceivedToday from "./pages/admin/TubesReceivedToday/TubesReceivedToday";
import ReworksRequestForm from "./pages/admin/ReworksRequestForm/ReworksRequestForm";
import ReworksRequestedPage from "./pages/admin/ReworksRequested/ReworksRequested";
import KitOperationalDashboard from "./pages/admin/KitOperationalDashboard";
import SamplingLocationPublic from "./containers/SamplingLocation/publicIndex";
import SamplingLocations from "./pages/admin/SamplingLocations/SamplingLocations";
import SamplingLocation from "./pages/admin/SamplingLocation/SamplingLocation";
import NotFound from "./pages/NotFound/NotFound";
import KitsReceived from "./pages/admin/KitsReceived/KitsReceived";
import KitIssues from "./pages/admin/KitIssues/KitIssues";
import KitStatusPage from "./pages/admin/KitStatus/KitStatus";
import KitLookupPage from "./pages/admin/KitLookup/KitLookup";
import KitReceiptPage from "pages/admin/KitReceipt/KitReceipt";
import OrderFulfillment from "./pages/admin/OrderFulfillment/OrderFulfillment";
import TriageSheet from "./pages/admin/TriageSheet/TriageSheet";
import Programs from "./pages/admin/Programs/Programs";
import PcrUploadFormPage from "./pages/admin/PcrUploadForm/PcrUploadForm";
import PcrUploadsPage from "./pages/admin/PcrUploads/PcrUploads";
import LCMSUploadFormPage from "./pages/admin/LcmsUploadForm/LcmsUploadForm";
import LCMSUploadsPage from "./pages/admin/LcmsUploads/LcmsUploads";
import CreateProtocolPage from "./pages/admin/CreateProtocol/CreateProtocol";
import DataReview from "./pages/admin/Reports/Reports";
import OrderPlacements from "./pages/admin/OrderPlacements/OrderPlacements";
import OrderShipments from "./pages/admin/OrderShipments/OrderShipments";
import Invoicing from "./pages/admin/Invoicing/Invoicing";
import ProgramEnrollments from "./pages/admin/ProgramEnrollments/ProgramEnrollments";
import KitsByOrganization from "./pages/admin/KitsByOrganization/KitsByOrganization";
import ReviewWorkflow from "./pages/admin/ManualReview/ReviewWorkflow";

ReactGA.initialize("UA-124209075-2", {
  testMode: process.env.NODE_ENV === "test",
});

export default function Routes(props) {
  const location = useLocation();

  return (
    <>
      <Switch location={location}>
        <PublicRoute
          exact
          path="/"
          component={Auth}
          restricted
          header={false}
          footer={false}
        />
        <PublicRoute
          path="/account"
          component={Auth}
          header={false}
          footer={false}
        />
        <PrivateRoute exact path="/kits" component={Kits} />
        <PrivateRoute
          exact
          path="/tubes-received-today"
          component={TubesReceivedToday}
        />
        <PrivateRoute
          exact
          path="/admin/tubes-received"
          component={TubesReceivedToday}
        />
        <PrivateRoute exact path="/kit/:id" component={Kit} />
        <PrivateRoute exact path="/sampling-locations" component={Sites} />
        <PrivateRoute exact path="/order" component={OrderForm} />
        <PrivateRoute exact path="/orders" component={Orders} />
        <PrivateRoute exact path="/order/:id" component={Order} />
        <PrivateRoute exact path="/leads" component={Leads} />
        <PrivateRoute exact path="/organizations" component={Accounts} />
        <PrivateRoute
          exact
          path="/organizations/:id"
          component={AccountDetails}
        />
        <PrivateRoute
          exact
          path="/admin/order-fulfillment"
          component={OrderFulfillment}
        />
        <PrivateRoute
          exact
          path="/admin/sampling-locations"
          component={SamplingLocations}
        />
        <PrivateRoute
          exact
          path="/admin/sampling-locations/:id"
          component={SamplingLocation}
        />
        <PrivateRoute exact path="/admin/kits" component={AdminKitsPage} />
        <PrivateRoute
          exact
          path="/admin/kits/selection-statuses"
          component={KitSelectionStatuses}
        />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/user/:id" component={UserForm} />
        <PrivateRoute exact path="/report" component={Report} />
        <PrivateRoute
          exact
          path="/admin/rework-request"
          component={ReworksRequestForm}
        />
        <PrivateRoute
          exact
          path="/admin/reworks-requested"
          component={ReworksRequestedPage}
        />
        <PrivateRoute
          exact
          path="/admin/kit-receipt"
          component={KitReceiptPage}
        />
        <PrivateRoute
          exact
          path="/admin/kits-received"
          component={KitsReceived}
        />
        <PrivateRoute exact path="/admin/kit-issues" component={KitIssues} />
        <PrivateRoute
          exact
          path="/admin/kits-by-organization"
          component={KitsByOrganization}
        />
        <PrivateRoute
          exact
          path="/admin/triage-sheet"
          component={TriageSheet}
        />
        <PrivateRoute
          exact
          path="/admin/kits-operational-dashboard"
          component={KitOperationalDashboard}
        />
        <PrivateRoute
          exact
          path="/admin/order-placements"
          component={OrderPlacements}
        />
        <PrivateRoute
          exact
          path="/admin/order-shipments"
          component={OrderShipments}
        />
        <PrivateRoute exact path="/admin/programs" component={Programs} />
        <PrivateRoute
          exact
          path="/admin/program-enrollments"
          component={ProgramEnrollments}
        />
        <Redirect from="/admin/qpcr-uploads" to="/admin/pcr-uploads" />
        <Redirect from="/admin/qpcr-upload-form" to="/admin/pcr-upload-form" />
        <PrivateRoute
          exact
          path="/admin/pcr-uploads"
          component={PcrUploadsPage}
        />
        <PrivateRoute
          exact
          path="/admin/pcr-upload-form"
          component={PcrUploadFormPage}
        />
        <PrivateRoute
          exact
          path="/admin/lcms-uploads"
          component={LCMSUploadsPage}
        />
        <PrivateRoute
          exact
          path="/admin/lcms-upload-form"
          component={LCMSUploadFormPage}
        />
        <PrivateRoute
          exact
          path="/admin/create-protocol-version"
          component={CreateProtocolPage}
        />
        <PrivateRoute
          exact
          path="/admin/kit-lookup"
          component={KitLookupPage}
        />
        <PrivateRoute
          exact
          path="/admin/kit-status-report"
          component={KitStatusPage}
        />
        <PrivateRoute
          exact
          path="/admin/manual-review"
          component={ReviewWorkflow}
        />
        <PrivateRoute exact path="/magic-table/:model" component={MagicTable} />
        <PrivateRoute exact path="/magic-table" component={MagicTable} />
        <PrivateRoute
          exact
          path="/runbooks/category/:categoryId"
          component={RunbooksPage}
        />
        <PrivateRoute exact path="/runbooks/:id" component={RunbooksPage} />
        <PublicRoute exact path="/sample" component={PublicSample} />
        <PublicRoute exact path="/kit-pilot-sample" component={PilotSample} />
        <PrivateRoute exact path="/admin/data-review" component={DataReview} />
        <PrivateRoute exact path="/admin/invoicing" component={Invoicing} />
        <PublicRoute
          exact
          path="/sampling-location-metadata"
          component={SamplingLocationPublic}
        />
        {process.env.REACT_APP_DEMO_ROUTES === "true" && (
          <PublicRoute path="/demo" component={Demo} />
        )}

        {/* Signup forms */}
        <Route path="/sign-up" component={NetworkSignUp} />

        <Route path="/network-opt-in" component={NetworkCustomerOptIn} />

        <Route path="/cdc-sign-up" component={CDCSignUp} />

        <Route path="/nida-sign-up" component={NIDASignUp} />

        {/* 404 Page */}
        <PublicRoute path="*" component={NotFound} />
      </Switch>
    </>
  );
}
