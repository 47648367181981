import _ from "lodash";
import React from "react";
import moment from "moment";
import { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Collapse,
  Form,
  notification,
  PageHeader,
  Row,
  Table,
  Tooltip,
  Typography,
} from "antd";
import {
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import { orderServiceCustomRoute } from "../../../api/orderService";
import KitDestinations from "./KitDestinations";
import KitAssays from "components/KitAssays";
import KitNotes from "components/KitNotes";
import Loader from "components/Loader/Loader";
import { saveBrowserDataToFile } from "../../../utils";

import "./KitReceipt.css";

const { Link, Text } = Typography;
const { Panel } = Collapse;

// Check for these special error messages
const ALREADY_RECEIVED =
  "Kit cannot be received by the lab more than once. Use the Biobot admin portal";
const NO_KIT_FOUND = "No kit found matching the given shipping kit id";

const KitReceiptPage = () => {
  const [form] = Form.useForm();
  const [kitReceiptData, setKitReceiptData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const searchRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      title: "Kit Id",
      key: "shipping_kit_id",
      render: (record) => (
        <Link href={`/kit/${record.kit.id}`} target="_blank">
          {record.kit.shipping_kit_id ?? "ID Pending"}
        </Link>
      ),
      width: "10%",
    },
    {
      title: "Assays",
      key: "kit-assays",
      dataIndex: ["selections", "products"],
      render: (products) => <KitAssays products={products} />,
      width: "15%",
    },
    {
      title: "Destinations",
      key: "kit-destination",
      render: (record) => <KitDestinations record={record} />,
      width: "15%",
    },
    {
      title: "Notes",
      key: "kit-notes",
      render: (record) => (
        <KitNotes
          kit={record.kit}
          onSave={handleNotesUpdate}
          defaultValue={record.kit.kit_notes}
        />
      ),
      width: "10%",
    },
    {
      title: "Programs",
      key: "kit-programs",
      dataIndex: ["selections", "statuses"],
      render: (statuses) =>
        _.isEmpty(statuses) ? (
          <>N/A</>
        ) : (
          <>
            {statuses?.map((x) => (
              <Text>
                {x.program?.short_name}
                <br />
              </Text>
            ))}
          </>
        ),
      width: "15%",
    },
    {
      title: "Customer",
      key: "kit-customer",
      render: (record) => (
        <>
          <Link
            href={`/organizations/${record.kit.order.order_placement.organization.id}`}
            target="_blank"
          >
            {record.kit.order.order_placement.organization.organization_name}
          </Link>
          <br />
          {`\u{21B3}`}{" "}
          {!_.isNil(record.kit.sample) ? (
            <Link
              href={`sampling-locations/${record.kit.sample.sampling_location_id.id}`}
              target="_blank"
            >
              {record.kit.sample.sampling_location_id.sampling_location_name}
            </Link>
          ) : (
            <Text type="warning">
              <Tooltip
                className="mr-1"
                title="SKU Assignment may be ambiguous until sample log is submitted."
              >
                <ExclamationCircleOutlined />
              </Tooltip>
              No Sample Log
            </Text>
          )}
        </>
      ),
      width: "20%",
    },
    {
      title: "Date Received",
      key: "date_received",
      dataIndex: ["kit", "date_received"],
      render: (val) => {
        return val ? moment(val).format("MM/DD/YY HH:mm") : "";
      },
      width: "15%",
    },
  ];

  const handleNotesUpdate = (newNotes) => {
    const newKitData = { ...kitReceiptData[0]["kit"], kit_notes: newNotes };
    setKitReceiptData([{ ...kitReceiptData[0], kit: newKitData }]);
  };

  const handleKitReceipt = async (value) => {
    if (_.isEmpty(value)) {
      return;
    }

    // Add the previous lookup to the top of the history table before setting a loading spinner
    setTableData(kitReceiptData.concat(tableData));
    setKitReceiptData([]);
    setIsLoading(true);

    // Send request to the API to receive the kit and parse disposition from response
    await orderServiceCustomRoute({
      model: "kits",
      path: "received_by_lab",
      method: "post",
      params: { shipping_kit_id: value },
      data: { dry_run: true },
    })
      .then((kitReceived) => {
        setIsLoading(false);
        setKitReceiptData([kitReceived]);
      })
      .catch((e) => {
        let notifLvl = "warning";
        let notifConfig = { duration: 0, placement: "top", top: 80 };

        // If user scanned a kit that was already received, show that kit's data
        let newTableData = [];
        switch (e.response?.data?.message?.err) {
          case ALREADY_RECEIVED:
            notifConfig.message = `${value} has already been received`;
            newTableData.push(e.response?.data?.message);
            break;
          case NO_KIT_FOUND:
            notifConfig.message = `No kit found for ${value}`;
            break;
          default:
            notifLvl = "error";
            notifConfig.message = `Error receiving kit ${value}`;
            notifConfig.description = <pre>{e.stack}</pre>;
        }

        notification[notifLvl](notifConfig);

        setIsLoading(false);
        setKitReceiptData(newTableData);
      });

    // Clear out search box and refocus on it
    form.resetFields(["input_shipping_kit_id"]);
    searchRef.current.focus();
  };

  const handleExport = () => {
    // Construct the data for export
    const allRawReceiptData = kitReceiptData.concat(tableData);

    const headerRow =
      "Shipping Kit ID,Organization,Sampling Location,Programs,Notes\n";
    const csvData = allRawReceiptData.map((obj) => {
      const shippingKitId = obj.kit.shipping_kit_id;
      const organization =
        obj.kit.order?.order_placement?.organization?.organization_name || "";
      const samplingLocation =
        obj.kit.sample?.sampling_location_id?.sampling_location_name ||
        "No sample log";
      const programs =
        obj.selections.statuses
          ?.map((status) => `${status.program?.code}:${status.selectionStatus}`)
          .join(";") || "";
      const notes = obj.kit.kit_notes;

      const fullExportRow = `${shippingKitId},${organization},${samplingLocation},${programs},"${notes}"`;

      return `${fullExportRow}\n`;
    });
    const fullData = headerRow.concat(csvData.join(""));

    // Construct a filename for the export
    const today = new Date();
    const exportName = `kits_received_${today.toLocaleDateString()}_${today.toLocaleTimeString()}`;

    saveBrowserDataToFile(fullData, exportName, "text/csv");
  };

  return (
    <div className="admin-kits-page" style={{ height: "100%" }}>
      <PageHeader title="Kit Receiving" />

      <Row gutter={16} align="left" className="mb-1 ml-2">
        <Col span={1}></Col>
        <Col span={10}>
          <Form form={form}>
            <Form.Item
              data-cy="input_shipping_kit_id"
              name="input_shipping_kit_id"
              label={
                <>
                  <Text>Shipping Kit ID</Text>
                  <Tooltip
                    title={
                      <span>
                        Scan the shipping kit ID
                        <br />
                        (eg. 'KIT-AA123')
                      </span>
                    }
                  >
                    <QuestionCircleOutlined className="pl-1" />
                  </Tooltip>
                </>
              }
            >
              <Search
                placeholder="Enter shipping kit id"
                size="medium"
                ref={searchRef}
                autoComplete={"off"}
                type={"search"}
                enterButton
                onPressEnter={(e) => {
                  e.preventDefault();
                  handleKitReceipt(e.target.value);
                }}
                onSearch={(value) => {
                  handleKitReceipt(value);
                }}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col span={3} />
        <Col span={2}>
          <Button className="mb-3" type="primary" onClick={handleExport}>
            Export Kits Received
          </Button>
        </Col>
      </Row>

      {isLoading ? (
        <Loader />
      ) : (
        <Table
          className="admin-kit-received-table ml-3"
          rowKey="lookup-id"
          columns={columns}
          dataSource={kitReceiptData}
          pagination={false}
        />
      )}

      <Collapse ghost className="ml-1 mt-3">
        <Panel
          header={
            <>
              <Text>Previous Scans</Text>
              <Tooltip title="History is cleared when leaving or refreshing this page.">
                <QuestionCircleOutlined className="pl-1" />
              </Tooltip>
            </>
          }
        >
          <Table
            className="admin-kit-received-history-table ml-3"
            rowKey="history-id"
            columns={columns.slice(0, 2).concat(columns.slice(3))} // Don't allow modifying notes of previous lookups
            dataSource={tableData}
            pagination={false}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default withRouter(KitReceiptPage);
